import * as React from "react"
import { Helmet } from "react-helmet"

import favicon from "../images/favicon.png"
import ogImage from "../images/og.png"
import rns from "../images/react-native-school.png"
import siteData from "../site-data"

export const SEO = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  canonical_url,
}) => {
  const siteUrl = siteData.siteUrl
  const metaDescription = description || siteData.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: siteData.title,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${ogImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          // TODO: Make this the author of the post/course
          name: `twitter:creator`,
          content: siteData.author || ``,
        },
        {
          name: `twitter:site`,
          content: siteData.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: `${siteUrl}${rns}`,
        },
        {
          name: "google-site-verification",
          content: "RCP46RJPu_BXaX9-7tKlLG12k9b1r3x2BtKkhAXfE10",
        },
      ].concat(meta)}
      link={[
        { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
        ...(canonical_url
          ? [
              {
                rel: "canonical",
                href: canonical_url,
              },
            ]
          : []),
      ]}
    />
  )
}
