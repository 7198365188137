import React from "react"

import { Header } from "../components/Header"
import { Footer } from "../components/Footer"

export const DefaultLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col antialiased">
      <Header />
      <div className="flex-1">{children}</div>
      <Footer />
    </div>
  )
}
