import React from "react"
import { Link } from "gatsby"

import logo from "../images/logo-text.svg"
import { clickEvent } from "../lib/analytics"

const leftLinks = [
  { text: "blog", to: "/tutorials" },
  { text: "courses", to: "/classes" },
]

const rightLinks = [
  {
    text: "login",
    to: "https://sso.teachable.com/secure/64145/users/sign_in?clean_login=true&reset_purchase_session=1",
    external: true,
  },
  {
    text: "join",
    to: "/#pricing",
    button: true,
    onClick: () => {
      clickEvent({
        event_category: "CTA",
        event_label: "Navbar Join",
      })
    },
  },
]

const Navlink = ({ text, to, external, button, onClick }) => {
  let extra = ""
  if (button) {
    extra = "bg-brand-green px-3 py-2 my-4 rounded-md text-center font-bold"
  }

  const classNames = `block py-2 my-2 md:my-1 text-white ${extra}`

  if (external) {
    return (
      <a href={to} className={classNames} target="_blank" rel="noreferrer">
        {text}
      </a>
    )
  }

  return (
    <Link className={classNames} to={to} onClick={onClick}>
      {text}
    </Link>
  )
}

export const Header = () => {
  const [navExpanded, setNavExpanded] = React.useState(false)

  return (
    <header className="bg-brand-blue">
      <div className="max-w-screen-xl mx-auto flex flex-wrap flex-row justify-between items-center md:items-center md:space-x-4 py-3 px-6 relative">
        <Link to="/" className="block">
          <img
            className="h-10"
            src={logo}
            alt="React Native School Logo"
            title="React Native School Logo"
          />
        </Link>
        <button
          className="inline-block md:hidden w-8 h-8 text-white p-1"
          type="button"
          onClick={() => setNavExpanded(!navExpanded)}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <nav
          className={`${
            !navExpanded && "hidden"
          } bg-brand-blue absolute md:relative top-16 left-0 md:top-0 z-20 md:flex flex-col md:flex-row md:space-x-6 font-medium w-full md:w-auto shadow-md md:shadow-none p-8 pt-0 md:p-0 items-center`}
        >
          {leftLinks.map(l => (
            <Navlink {...l} key={l.to} />
          ))}
          {rightLinks.map(l => (
            <Navlink {...l} key={l.to} />
          ))}
        </nav>
      </div>
    </header>
  )
}
