export default {
  monthlyPurchaseUrl:
    "https://learn.reactnativeschool.com/purchase?product_id=834389",
  monthlyPrice: "19",
  yearlyPurchaseUrl:
    "https://learn.reactnativeschool.com/purchase?product_id=3878927",
  yearlyPrice: "180",
  lifetimePurchaseUrl:
    "https://learn.reactnativeschool.com/purchase?product_id=4196879",
  lifetimePrice: "350",
  title: "React Native School",
  description:
    "Your one-stop shop for React Native tips, tricks, and tutorials.",
  author: "@learn_rn",
  siteUrl: process.env.SITE_URL,
  studentCount: 30300,
}
