import React from "react"
import { Link } from "gatsby"

import logo from "../images/logo.svg"

const links = [
  {
    name: "home",
    to: "/",
  },
  {
    name: "courses",
    to: "/classes",
  },
  {
    name: "blog",
    to: "/tutorials",
  },
  {
    name: "reviews",
    to: "/reviews",
  },
  {
    name: "topics",
    to: "/topics",
  },
  {
    name: "roadmap",
    to: "/roadmap",
  },
  {
    name: "login",
    to: "https://sso.teachable.com/secure/64145/users/sign_in?clean_login=true&reset_purchase_session=1",
    external: true,
  },
]

const socials = [
  {
    name: "twitter",
    to: "https://twitter.com/learn_rn",
  },
  {
    name: "youtube",
    to: "https://www.youtube.com/reactnativeschool",
  },
  {
    name: "github",
    to: "https://github.com/reactnativeschool",
  },
]

const Form = () => {
  // https://www.codewithlinda.com/blog/email-newsletter-subscription-for-gatsby-using-convertkit/
  const [email, setEmail] = React.useState("")
  const [status, setStatus] = React.useState(null)

  const FORM_URL = "https://app.convertkit.com/forms/804312/subscriptions"

  const handleSubmit = async e => {
    e.preventDefault()
    const data = new FormData(e.target)
    try {
      const response = await fetch(FORM_URL, {
        method: "post",
        body: data,
        headers: {
          accept: "application/json",
        },
      })
      setEmail("")
      const json = await response.json()
      if (json.status === "success") {
        setStatus("SUCCESS")
        return
      }
    } catch (err) {
      setStatus("ERROR")
      console.log(err)
    }
  }

  return (
    <>
      <form
        action={FORM_URL}
        method="post"
        onSubmit={handleSubmit}
        className="flex flex-col"
      >
        <input
          className="py-3 px-6 rounded-md text-xl"
          type="email"
          name="email_address"
          placeholder="email address"
          onChange={e => setEmail(e.target.value)}
          value={email}
          required
        />

        {status === "SUCCESS" && (
          <p className="text-white pt-1">
            Please go confirm your subscription!
          </p>
        )}
        {status === "ERROR" && (
          <p className="text-white pt-1">
            Oops, Something went wrong! Try again.
          </p>
        )}

        <button
          className="shadow-md bg-brand-green text-white font-medium py-3 px-6 mt-4 rounded-md text-xl"
          type="submit"
        >
          submit
        </button>
      </form>
    </>
  )
}

export const Footer = () => (
  <footer className="bg-brand-blue">
    <div className="py-8 flex flex-col md:flex-row max-w-screen-xl mx-auto px-4">
      <div className="py-6 flex flex-col flex-1">
        {links.map(l => {
          const c = "text-white text-xl font-normal pt-1"

          if (l.external) {
            return (
              <a
                href={l.to}
                className={c}
                target="_blank"
                rel="noreferrer"
                key={l.to}
              >
                {l.name}
              </a>
            )
          }

          return (
            <Link className={c} to={l.to} key={l.to}>
              {l.name}
            </Link>
          )
        })}

        <p className="text-white text-md font-normal pt-1 mt-8">
          A{" "}
          <a
            href="https://www.handlebarlabs.com"
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            Handlebar Labs
          </a>{" "}
          Product
        </p>
      </div>
      <div className="py-6 flex flex-1 flex-col">
        <p className="text-xl text-white mb-4">
          Join our email list and get{" "}
          <span className="font-bold">free access to our crash course</span> on
          building React Native apps.
        </p>
        <Form />

        <div className="flex flex-row justify-center mt-6">
          {socials.map(s => (
            <a
              href={s.to}
              className="text-white px-4"
              key={s.name}
              target="_blank"
              rel="noreferrer"
            >
              <span className="">
                <i className={`fab fa-${s.name} fa-2x`} />
              </span>
            </a>
          ))}
        </div>
      </div>
      <div className="py-6 flex flex-1 items-center justify-center md:justify-end">
        <img
          className="h-60 opacity-75"
          src={logo}
          alt="React Native School Logo"
          title="React Native School Logo"
        />
      </div>
    </div>
  </footer>
)
